import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import "./Comments.scss";

const Comments = () => {
  const [commentText, setCommentText] = useState("");
  const userDetails = localStorage.getItem('userDetails');
  const [comments, setComments] = useState([
    {
      id: 1,
      avatar: <FaUserCircle size={30} />,
      name: "John Doe",
      text: "This is a sample comment!",
      time: "2 hours ago",
    },
    {
      id: 2,
      avatar: <FaUserCircle size={30} />,
      name: "Jane Smith",
      text: "Another comment on this post.",
      time: "1 hour ago",
    },
    {
      id: 3,
      avatar: <FaUserCircle size={30} />,
      name: "Alex Johnson",
      text: "Nice discussion happening here!",
      time: "30 minutes ago",
    },
  ]);

  const handlePostComment = () => {
    if (commentText.trim() !== "") {
      const newComment = {
        id: comments.length + 1,
        avatar: <FaUserCircle size={30} />,
        name: "You",
        text: commentText,
        time: "Just now",
      };
      setComments([newComment, ...comments]); // Add new comment to top
      setCommentText("");
    }
  };

  return (
    <div className="comments">
      {/* Input Section */}
      <div className="comments-input">
        <div className="comments-input-user">
          <div className="comments-input-user-avatar"><FaUserCircle size={40} className="comments-input-avatar" /></div>
          <div className="comments-input-user-name">Ellen Patrik</div>
        </div>
        
        <div className="comments-input-textbox">
          <textarea
            placeholder="Add a comment here..."
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
        </div>
        <div className="comments-input-button" onClick={handlePostComment}>
          <button className="primary-button">Post</button>
        </div>
      </div>

      {/* Comments List */}
      <div className="comments-list">
        {comments.map((comment) => (
          <div key={comment.id} className="comments-list-item">
            {comment.avatar}
            <div className="comments-list-item-content">
              <h4>
                {comment.name}{" "}
                <span className="comments-list-item-content-time">
                  {comment.time}
                </span>
              </h4>
              <p>{comment.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Comments;
