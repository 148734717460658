import React, { useContext, useEffect, useState } from "react";
import "./UserIncident.scss";
import FormLayout from "../../../common/components/Form/FormLayout";
import { getFormSections, getFormSectionQuestions } from "../services/api.services";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";

const UserIncident = () => {
  const [sectionData, setSectionData] = useState([]);
  const [sectionQuestionData, setSectionQuestionData] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [loading, setLoading] = useState(false);
  const { state } = useContext(AuthContext);

  const header = {
    Authorization: "Token " + state.token,
  };

  useEffect(() => {
    fetchFormSections();
  }, []);

  const fetchFormSections = async () => {
    setLoading(true);
    try {
      const response = await getFormSections(header);
      if (response.status === 200 && response.data?.results) {
        setSectionData(response.data.results);
        if (response.data.results.length > 0) {
          handleSectionSelect(response.data.results[0]); // Load first section by default
        }
      } else {
        toast.error("Failed to fetch sections: Invalid response format.");
      }
    } catch (error) {
      toast.error("Error fetching sections: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSectionSelect = async (section) => {
    setSelectedSection(section);
    setLoading(true);
    try {
      const response = await getFormSectionQuestions(header, section.id);
      if (response.status === 200 && response.data?.questions) {
        setSectionQuestionData(response.data.questions);
      } else {
        toast.error("Failed to fetch questions: Invalid response format.");
      }
    } catch (error) {
      toast.error("Error fetching questions: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user-policy">
      <div className="user-policy-title">
        <div className="user-policy-title-text">Incident Report Form</div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <FormLayout
          sectionData={sectionData}
          questionData={sectionQuestionData}
          selectedSection={selectedSection}
          onSectionSelect={handleSectionSelect}
        />
      )}
    </div>
  );
};

export default UserIncident;