import React from "react";
import "./IncidentDetails.scss";
import { FaChevronDown, FaPlusCircle } from "react-icons/fa";
import IncidentInfo from "../components/IncidentInfo";
import IncidentActions from "../components/IncidentActions";

const IncidentDetails = () => {
  return (
    <div>
      <div className="incident-details">
        <div className="incident-details-title">Incident Reports</div>
        <div className="incident-details-body">
          <IncidentInfo />
          <IncidentActions />
        </div>
      </div>
    </div>
  );
};

export default IncidentDetails;
