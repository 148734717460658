import React from "react";
import { Route, Routes } from "react-router-dom";
import UserIncident from "./user/views/UserIncident";
const AppUserPolicyRouter = () => {
  return (
    <Routes>
      <Route path="" element={<UserIncident/>} />
    </Routes>
  );
};

export default AppUserPolicyRouter;
