import React from 'react';
import './IncidentActionPlanning.scss';

const IncidentActionPlanning = () => {
    return (
        <div className="incident-action-planning">
            <h1>Incident Action Planning</h1>
            {/* Add your component content here */}
        </div>
    );
};

export default IncidentActionPlanning;