import React, { useRef, useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./ButtonTabs.scss";

const ButtonTabs = ({ items, activeTab, onTabChange }) => {
  const tabRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  // Function to update scroll arrows visibility
  const updateScrollButtons = () => {
    if (tabRef.current) {
      setCanScrollLeft(tabRef.current.scrollLeft > 0);
      setCanScrollRight(
        tabRef.current.scrollLeft < tabRef.current.scrollWidth - tabRef.current.clientWidth
      );
    }
  };

  // Handle scrolling with mouse wheel
  useEffect(() => {
    const handleScroll = (event) => {
      if (tabRef.current) {
        event.preventDefault();
        tabRef.current.scrollLeft += event.deltaY;
      }
    };

    const tabContainer = tabRef.current;
    if (tabContainer) {
      tabContainer.addEventListener("wheel", handleScroll);
      tabContainer.addEventListener("scroll", updateScrollButtons);
      updateScrollButtons();
    }

    return () => {
      if (tabContainer) {
        tabContainer.removeEventListener("wheel", handleScroll);
        tabContainer.removeEventListener("scroll", updateScrollButtons);
      }
    };
  }, []);

  // Function to scroll tabs on button click
  const scrollTabs = (direction) => {
    if (tabRef.current) {
      tabRef.current.scrollBy({ left: direction * 100, behavior: "smooth" });
    }
  };

  return (
    <div className="button-tabs-container">
      {canScrollLeft && (
        <div className="scroll-button left" onClick={() => scrollTabs(-100)}>
          <FaChevronLeft />
        </div>
      )}

      <div className="button-tabs" ref={tabRef}>
        {items.map((item) => (
          <div
            key={item}
            className={`button-tabs-item ${activeTab === item ? "button-tabs-item-active" : ""}`}
            onClick={() => onTabChange(item)}
          >
            {item}
          </div>
        ))}
      </div>

      {canScrollRight && (
        <div className="scroll-button right" onClick={() => scrollTabs(100)}>
          <FaChevronRight />
        </div>
      )}
    </div>
  );
};

export default ButtonTabs;
