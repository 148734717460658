import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CommonSidebar from "../../../common/components/CommonSidebar";
import incidentIcon from "../../admin/images/incident.png";
import UserHeader from "../../../common/components/UserHeader";
import "./IncidentUser.scss";
import AppUserIncidentRouter from "../../AppUserIncidentRouter";

const IncidentUser = ({ isAdmin }) => {
  const navigate = useNavigate();
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());
  const [navbarData, setNavbarData] = useState([
    {
      name: "Incident",
      icon: incidentIcon,
      onClick: () => navigate("/incident/user"),
    },
  ]);
  const toggleSidebar = () => {
    setOpenMobileNav(!openMobileNav);
  };

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }

  return (
    <div className="incident-user">
      <div className="incident-user-body">
        <CommonSidebar
          setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
          navbarData={navbarData}
          deviceWidth={deviceWidth}
          openMobileNav={openMobileNav}
        ></CommonSidebar>

        <div className="incident-user-body-content">
          <div className="incident-user-body-content-header">
            <UserHeader toggleSidebar={toggleSidebar} />
          </div>
          <div className="incident-user-body-content-elements">
            <AppUserIncidentRouter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentUser;
