import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import "./AppStartCourse.scss";
import "../../../theme/_buttons.scss";
import toast from "react-hot-toast";
import { setStartCourse } from "../services/api.services";
import incompleteIcon from "../../images/icons/incomplete.png";
import AppLoader from "./AppLoader";
import { useDashboardContext } from "../contexts/DashboardContext";
import EmptyContainer from "../../../common/components/EmptyContainer";

const AppStartCourse = ({
  selectedCourses,
  isStartButton,
  header,
  setStatus,
  status,
  courseCount,
  setPageNo,
}) => {
  const navigate = useNavigate();
  const { setUserCourse } = useDashboardContext() || {};
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  const count = Object.keys(selectedCourses).length;

  useEffect(() => {
    const statuses = [null, "in_progress", "pending", "completed"];
    const index = statuses.indexOf(status);
    setActiveIndex(index !== -1 ? index : 0);
  }, [status]);

  useEffect(() => {
    if (selectedCourses !== undefined && selectedCourses !== null) {
      setIsLoading(false);
    }
  }, [selectedCourses]);

  const handleSelectOption = (data) => {
    navigate("/" + data["course"].name, {
      state: {
        userCourseId: data.id,
        courseId: data["course"].id,
        userCourseName: data["course"].name,
      },
    });
    setUserCourse(data);                                          // Set the user course in dashboard context
    localStorage.setItem("selectedNavTab", 0);
    if (isStartButton) {
      invokeStartCourse(data.id);
    }
  };

  const invokeStartCourse = async (courseId) => {
    try {
      const response = await setStartCourse(header, courseId);
      if (response.status === 200) {
        toast.success("Course started successfully!");
      } else {
        toast.error("Failed to Start Course");
      }
    } catch (error) {
      toast.error("Error Starting Course " + error.message);
    }
  };

  const handleTitleBarClick = (index) => {
    setActiveIndex(index);
    const statuses = [null, "in_progress", "pending", "completed"];
    setPageNo(1);
    setStatus(statuses[index]);
  };

  if (isLoading) {
    return (
      <div className="course_wrapper">
        <AppLoader />
      </div>
    );
  }

  return (
    <>
      {isStartButton && (
        <div className="course_titlebar">
          <ul className="course_titlebar-list">
            {["All Courses", "In Progress", "Pending", "Completed"].map(
              (title, index) => (
                <li
                  key={index}
                  className={`course_titlebar-list-item ${
                    activeIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleTitleBarClick(index)}
                >
                  <span className="course_titlebar-list-item-left"></span>
                  <span className="course_titlebar-list-item-right"></span>
                  <span className="course_titlebar-list-item-center">
                    {title}
                    {activeIndex === index && (
                      <span className="course_titlebar-list-item-center-count">
                        {courseCount && courseCount}
                      </span>
                    )}
                  </span>
                </li>
              )
            )}
          </ul>
        </div>
      )}

      <div className="course_wrapper">
        {count > 0 ? (
          <>
            {selectedCourses.map((data, index) => {
              const courseData = isStartButton ? data["course"] : data; 
              return (
                <div key={index} className="course">
                  <div className="course_header">
                    <div className="course_header_title">
                      <div className="course_header_title_name">
                        {courseData.name}
                      </div>
                    </div>
                  </div>
                  <div className="course_body">
                    <div className="course_body_image">
                      <img src={courseData.icon} alt="icon" />
                    </div>
                    <div className="course_body_details">
                      <div className="course_body_details_description">
                        <div className="course_body_details_description_title">
                          Description
                        </div>
                        <div className="course_body_details_description_text">
                          {courseData.description}
                        </div>
                      </div>
                      <div className="course_body_details_data">
                        <div className="course_body_details_data_info">
                          <div className="course_body_details_data_info_col">
                            <div className="course_body_details_data_info_col_key">
                              CPD Hours
                            </div>
                            <div className="course_body_details_data_info_col_value">
                              {courseData.duration}
                            </div>
                          </div>
                          <div className="course_body_details_data_info_col">
                            <div className="course_body_details_data_info_col_key">
                              Modules
                            </div>
                            <div className="course_body_details_data_info_col_value">
                              {courseData.module_count}
                            </div>
                          </div>
                        </div>
                        {isStartButton && (
                          <div className="course_body_details_data_button">
                            <div key={index}>  
                              <button
                                className={`primary-button ${
                                  data.status === "completed"
                                    ? "course_completed"
                                    : ""
                                }`}
                                onClick={() => handleSelectOption(data)} 
                              >
                                {data.status === "completed"
                                  ? "Completed"
                                  : data.status === "in_progress"
                                  ? "Resume"
                                  : "Start"}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <EmptyContainer
            message="No courses found!!"
            style={{
              height: "80vh",
              justifyContent: "start",
              paddingBlockStart: "80px",
            }}
          />
        )}
      </div>
    </>
  );
};

export default AppStartCourse;
