import React from "react";
import { FaChevronDown, FaPlusCircle } from "react-icons/fa";
import "./IncidentInfo.scss";

const IncidentInfo = () => {
  return (
    <div className="incident-info">
      {/* Event Information */}
      <div className="incident-info-event">
        <div className="incident-info-event-top">
          <div className="incident-info-event-top-left">
            <div className="incident-info-event-top-left-title">
              <div className="incident-info-event-top-left-title-incident_number">
                INC-001
              </div>
              <div className="incident-info-event-top-left-title-incident_name">
                Fall
              </div>
            </div>
            <div className="incident-info-event-top-left-status">
              <button className="primary-button">
                Open <FaChevronDown />
              </button>
            </div>
          </div>
          <div className="incident-info-event-top-right">
            <div className="incident-info-event-top-right-risk_score">
              <div className="incident-info-event-top-right-risk_score-key">
                Risk Score
              </div>
              <div className="incident-info-event-top-right-risk_score-value">
                2
              </div>
            </div>
            <div className="incident-info-event-top-right-action">
              <button className="secondary-button">Rescore Risk</button>
            </div>
          </div>
        </div>

        <div className="incident-info-event-mid">
          <DetailItem label="Location:" value="Residence 1" />
          <DetailItem label="Created Date:" value="21 Jul 2024, 9:27 am" />
          <DetailItem label="Date of the Event:" value="19 Jul 2024, 2:22 pm" />
        </div>

        <div className="incident-info-event-bottom">
          <div className="incident-info-event-bottom-description">
            <div className="incident-info-event-bottom-description-key">
              Description:
            </div>
            <div className="incident-info-event-bottom-description-value">
              Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos. Curabitur tempus urna at turpis condimentum
              lobortis. Ut commodo efficitur neque. Ut diam quam, semper iaculis
              condimentum ac, vestibulum eu nisl.
            </div>
          </div>
        </div>
      </div>

      {/* People Information */}
      <div className="incident-info-people">
        <PeopleItem label="Reporting Manager:" value="Ellen Patrick" />
        <PeopleItem label="Assignee:" value="Issac Timmy" />
        <div className="incident-info-people-item">
          <div className="incident-info-people-item-key">Managers:</div>
          <div className="incident-info-people-item-value">Ellen Patrick</div>
          <div className="incident-info-people-item-value">Grace Samuel</div>
        </div>
        <div className="incident-info-people-add_manager">
          <FaPlusCircle /> Add Manager
        </div>
      </div>
    </div>
  );
};

// Helper Component for Detail Items
const DetailItem = ({ label, value }) => {
  return (
    <div className="incident-info-event-mid-item">
      <div className="incident-info-event-mid-item-key">{label}</div>
      <div className="incident-info-event-mid-item-value">{value}</div>
    </div>
  );
};

// Helper Component for People Items
const PeopleItem = ({ label, value }) => {
  return (
    <div className="incident-info-people-item">
      <div className="incident-info-people-item-key">{label}</div>
      <div className="incident-info-people-item-value">{value}</div>
    </div>
  );
};

export default IncidentInfo;
