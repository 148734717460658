import React from "react";
import PropTypes from "prop-types";
import "./FormLayout.scss";
import FormNav from "./FormNav";
import SectionContent from "./SectionContent";

const FormLayout = ({ sectionData, questionData, selectedSection, onSectionSelect }) => {
  return (
    <div className="form-layout">
      <div className="form-layout-nav">
        <FormNav
          sections={sectionData}
          selectedSection={selectedSection}
          onSectionSelect={onSectionSelect}
        />
      </div>
      <div className="form-layout-content">
        <SectionContent selectedSection={selectedSection} questions={questionData} />
      </div>
    </div>
  );
};

FormLayout.propTypes = {
  sectionData: PropTypes.array.isRequired,
  questionData: PropTypes.array.isRequired,
  selectedSection: PropTypes.object,
  onSectionSelect: PropTypes.func.isRequired,
};

export default FormLayout;