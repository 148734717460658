import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaExclamationTriangle, FaRegTrashAlt, FaEye, FaPencilAlt, FaPhone } from "react-icons/fa";
import toast from "react-hot-toast";
import AdminCustomCheckbox from "../../../learning/admin/components/AdminCustomCheckbox";
import PopupModal from "../../../learning/admin/components/PopupModal";
import SearchBox from "../../../learning/user/components/SearchBox";
import Table from "../../../common/components/Table";
import AppPagination from "../../../learning/admin/components/AppPagination";
import "../../../theme/_buttons.scss";
import "./AdminIncidentReports.scss";
import Tooltip from "../../../common/components/Tooltip";

const AdminIncidentReports = () => {
  const navigate = useNavigate();
  const [isRemove, setIsRemove] = useState(false);
  const [key, setKey] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [checkedData, setCheckedData] = useState([]);
  const [data, setData] = useState([]);
  const itemClass = checkedData.length > 0 ? "active" : "inactive";
  const [requestNames, setRequestNames] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [incidentData, setIncidentData] = useState([
    {
      id: 1,
      reporter_name: "John Doe",
      reporter_email: "john.doe@example.com",
      reporter_phone: "+1234567890",
      date_of_incident: "2024-09-01",
      time: "10:30 AM",
      location: "Ward 5B",
      type: "Medication Error",
      severity: "High",
      file: "incident_photo_1.jpg",
    },
    {
      id: 2,
      reporter_name: "Jane Smith",
      reporter_email: "jane.smith@example.com",
      reporter_phone: "+0987654321",
      date_of_incident: "2024-09-03",
      time: "2:00 PM",
      location: "ICU",
      type: "Patient Fall",
      severity: "Critical",
      file: "incident_photo_2.jpg",
    },
    // Add more incidents as needed
  ]);


  const headers = [
    "#",
    "Incident ID",
    "Incident Name",
    "Date of the Event",
    "Reporting Manager",
    "Priority",
    "Status",
  ];

  const rows = [
    {
      "#": "1",
      "Incident ID": "INC-001",
      "Incident Name": "Fall",
      "Date of the Event": "19 Jul 2024, 2:22 pm",
      "Reporting Manager": "Ellen Patrik",
      Priority: "High",
      Status: "Open",
    },
    {
      "#": "2",
      "Incident ID": "INC-002",
      "Incident Name": "Injury",
      "Date of the Event": "21 Jul 2024, 9:02 am",
      "Reporting Manager": "Will Thomas",
      Priority: "Low",
      Status: "In progress",
    },
    // Add the rest of the rows...
  ];

  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(5);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleNavigate = () => {
    navigate("/incident/admin/incident-report-form/");
  };

  const handleFileDownload = (fileUrl, fileName) => {
    try {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // toast.success("File downloaded successfully!");
    } catch (error) {
      toast.error("Failed to download the file.");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleRemoveData = async () => {
    if (checkedData.length === 0) {
      toast.error("No items selected for deletion.");
      return;
    }
    toast.success("Data removed successfully.");
    setCheckedData([]);
    setIsRemove(false);
    setKey(!key);
  };

  const handleCancelClick = () => {
    setCheckedData([]);
    setIsRemove(false);
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setKey(!key);
  };

  const handleRequestSearch = (searchText) => {
    setSearchKey(searchText);
    return requestNames;
  };
  const handleClearRequestSearch = () => {
    setSearchKey("");
    setKey(!key);
  };
  const handleRequestValueClick = (requestName) => {
    setSearchKey(requestName);
  };

  const formatFileName = (url) => {
    return url.split("/").pop().split("?")[0];
  };

  return (
    <div className="admin_incident_reports">
      <div className="admin_incident_reports-title">Incident Reports</div>
      <div className="admin_incident_reports-body">
        <div className="admin_incident_reports-body-tab-content">
          <div className="admin_incident_reports-body-tab-content-top">

            <button
              className="primary-button"
              style={{ width: "150px" }}
              onClick={() => handleNavigate()}
            >
              <FaExclamationTriangle />
              Report Incident
            </button>

            <div className="admin_incident_reports-body-tab-content-top-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleRequestSearch}
                onValueClick={handleRequestValueClick}
                onClearSearch={handleClearRequestSearch}
                useDynamicSearch={true}
              />
            </div>
          </div>

          <Table headers={headers} rows={rows} />
          <div style={{ padding: "50px", textAlign: "center" }}>
            {/* <Tooltip content={<div><strong>Incident Name:</strong> Fall<br /><strong>Date:</strong> 19 Jul 2024</div>} position="top">
              <button>Hover over me</button>
            </Tooltip> */}

            <Tooltip content={
              <div class="incident-tooltip">
                <div class="incident-tooltip-container">
                  <div class="incident-tooltip-container-header">
                    <div class="incident-tooltip-container-header-row">
                      <div class="incident-tooltip-container-header-row-item">

                        <div class="incident-tooltip-container-header-row-item-value">Hailey John</div>

                      </div>
                      <div class="incident-tooltip-container-header-row-item">
                        <div class="incident-tooltip-container-header-row-item-key">Age</div>:
                        <div class="incident-tooltip-container-header-row-item-value">66</div>

                      </div>
                    </div>
                    <div class="incident-tooltip-container-header-row">
                      <div class="incident-tooltip-container-header-row-item">
                        <div class="incident-tooltip-container-header-row-item-key">Issue</div>:
                        <div class="incident-tooltip-container-header-row-item-value">Fall</div>

                      </div>
                      <div class="incident-tooltip-container-header-row-item">
                        <div class="incident-tooltip-container-header-row-item-key">Risk Score</div>:
                        <div class="incident-tooltip-container-header-row-item-value">2</div>
                      </div>
                    </div>
                  </div>


                  <div class="incident-tooltip-container-body">
                    <div class="incident-tooltip-container-body-details">
                      <div class="incident-tooltip-container-body-details-title">Details</div>
                      <div class="incident-tooltip-container-body-details-text">The individual was walking in the hallway near their room when they
                        suddenly lost balance and fell to the floor. The individual was found on
                        the floor, sitting up but complaining of slight discomfort in the knee.
                        Staff immediately approached to assess the situation and provide assistance.</div>

                    </div>
                    <div class="incident-tooltip-container-body-details">
                      <div class="incident-tooltip-container-body-details-title">Raised By
                      </div>
                      <div class="incident-tooltip-container-body-details-text">Jenny Williams</div>

                    </div>
                    <div class="incident-tooltip-container-body-details">
                      <div class="incident-tooltip-container-body-details-title">Reporting Manager</div>
                      <div class="incident-tooltip-container-body-details-text">Ellen Patrick</div>

                    </div>
                    <div class="incident-tooltip-container-body-details">
                      <div class="incident-tooltip-container-body-details-title">Immediate Actions</div>
                      <div class="incident-tooltip-container-body-details-text">
                        The individual was assessed for injuries immediately after the fall.
                        No visible injuries were noted initially.
                        Vital signs were checked, and the individual appeared stable with no signs of shock.</div>

                    </div>

                  </div>
                </div>
              </div>
            } position="right">
              <span style={{ marginLeft: "20px", cursor: "pointer" }}>Hover on this text</span>
            </Tooltip>
            {/*             
            <Tooltip content="Bottom positioned tooltip!" position="bottom">
              <div style={{ marginTop: "50px", padding: "10px", border: "1px dashed #333", display: "inline-block" }}>
                Hover on this box
              </div>
            </Tooltip>

            <Tooltip content={<img src="https://via.placeholder.com/100" alt="Tooltip Image" />} position="left">
              <div style={{ marginTop: "50px", padding: "10px", border: "1px dashed #333", display: "inline-block" }}>
                Hover on this for an image
              </div>
            </Tooltip> */}
          </div>


          {dataCount > recordsPerPage && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          )}
        </div>
      </div>

      {/* Popup Modal */}
      {isRemove && (
        <PopupModal setIsOpen={setIsRemove}>
          <div className="unenroll_confirm_modal-body">
            <div className="unenroll_confirm_modal-body-title">
              Remove Incident(s)
            </div>
            <div className="unenroll_confirm_modal-body-text">
              Are you sure you want to remove selected{" "}
              {checkedData.length < 2 ? "incident" : "incidents"}?
            </div>
            <div className="unenroll_confirm_modal-body-buttons">
              <button
                className="primary-button"
                onClick={() => {
                  handleRemoveData();
                }}
              >
                Confirm
              </button>
              <button className="secondary-button" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminIncidentReports;
