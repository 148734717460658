import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./Table.scss";
import Tooltip from "./Tooltip";

const Table = ({ headers, rows }) => {
  const navigate = useNavigate();
  return (
    <div className="responsive-table-container">
      <table className="responsive-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} className={index === 0 ? "sticky" : ""}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex} onClick={() => navigate(`/incident/admin/incident-details`)}>
              {Object.values(row).map((cell, cellIndex) => (
                <td key={cellIndex} className={cellIndex === 0 ? "sticky" : ""}>
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Table;
