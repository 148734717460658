import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./SectionContent.scss";
import Accordion from "../Accordion";
import RadioInput from "./Questions/RadioInput";
import TextInput from "./Questions/TextInput";
import DropdownInput from "./Questions/DropdownInput";
import DateTimeInput from "./Questions/DateTimeInput";

const SectionContent = ({ selectedSection, questions }) => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [optionsData, setOptionsData] = useState({});

  const handleInputChange = (questionId, selectedValue) => {
    setSelectedAnswers((prevState) => ({
      ...prevState,
      [questionId]: selectedValue,
    }));
  };

  const fetchOptions = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };

  useEffect(() => {
    questions.forEach(async (question) => {
      if (question.options?.type === "api") {
        const options = await fetchOptions(question.options.values);
        setOptionsData((prevState) => ({
          ...prevState,
          [question.id]: options,
        }));
      }
    });
  }, [questions]);

  const renderQuestionComponent = (question) => {
    const { id, question_text, input_type, options, placeholder } = question;

    switch (input_type) {
      case "text":
        return (
          <TextInput
            key={id}
            label={question_text}
            name={id}
            value={selectedAnswers[id] || ""}
            onChange={(e) => handleInputChange(id, e.target.value)}
            placeholder={placeholder}
          />
        );
      case "datetime":
        return (
          <DateTimeInput
            key={id}
            label={question_text}
            name={id}
            value={selectedAnswers[id] || ""}
            onChange={(e) => handleInputChange(id, e.target.value)}
            placeholder={placeholder}
          />
        );
      case "radio":
        const radioOptions = options?.type === "list" ? options.values : [];
        return (
          <RadioInput
            key={id}
            questionId={id}
            label={question_text}
            options={radioOptions.map((option) => ({ value: option, text: option }))}
            selectedAnswer={selectedAnswers[id] || ""}
            handleChange={handleInputChange}
          />
        );
      case "dropdown":
        const dropdownOptions = optionsData[id] || [];
        return (
          <DropdownInput
            key={id}
            label={question_text}
            options={dropdownOptions}
            selectedOption={selectedAnswers[id] || ""}
            onOptionSelect={(option) => handleInputChange(id, option)}
          />
        );
      default:
        return <p key={id}>Unsupported question type: {input_type}</p>;
    }
  };

  if (!selectedSection) return <p>Please select a section.</p>;

  return (
    <div className="section-content">
      <Accordion
        title={selectedSection.name}
        content={<>{questions.map((question) => renderQuestionComponent(question))}</>}
      />
    </div>
  );
};

SectionContent.propTypes = {
  selectedSection: PropTypes.object,
  questions: PropTypes.array.isRequired,
};

export default SectionContent;