import React, { useState } from "react";
import ButtonTabs from "../../../common/components/ButtonTabs";
import "./AdminIncidentForm.scss";
// import PersonalDetails from "./sections/PersonalDetails";
// import IncidentOverview from "./sections/IncidentOverview";
// import Safeguarding from "./sections/Safeguarding";
// import PeopleInvolved from "./sections/PeopleInvolved";
// import EquipmentMedical from "./sections/EquipmentMedical";
// import ActualImpact from "./sections/ActualImpact";
// import ActionsTaken from "./sections/ActionsTaken";
// import PoliceInvolvement from "./sections/PoliceInvolvement";
// import Medication from "./sections/Medication";
// import MissingPerson from "./sections/MissingPerson";

const tabItems = [
  "Personal Details",
  "Incident Overview",
  "Safeguarding Consideration",
  "People Involved",
  "Equipment and Medical Devices",
  "Actual Impact",
  "Actions Taken",
  "Police Involvement",
  "Medication",
  "Missing Person",
];

const AdminIncidentForm = () => {
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  return (
    <div>
      <ButtonTabs items={tabItems} activeTab={selectedTab} onTabChange={setSelectedTab} />
      
      {/* Render Section Based on Selected Tab */}
      <div className="admin-incident-form-content">
        {/* {selectedTab === "Personal Details" && <PersonalDetails />}
        {selectedTab === "Incident Overview" && <IncidentOverview />}
        {selectedTab === "Safeguarding Consideration" && <Safeguarding />}
        {selectedTab === "People Involved" && <PeopleInvolved />}
        {selectedTab === "Equipment and Medical Devices" && <EquipmentMedical />}
        {selectedTab === "Actual Impact" && <ActualImpact />}
        {selectedTab === "Actions Taken" && <ActionsTaken />}
        {selectedTab === "Police Involvement" && <PoliceInvolvement />}
        {selectedTab === "Medication" && <Medication />}
        {selectedTab === "Missing Person" && <MissingPerson />} */}
      </div>
    </div>
  );
};

export default AdminIncidentForm;
