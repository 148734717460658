import React, { useState } from "react";
import ButtonTabs from "../../../../common/components/ButtonTabs";
import Comments from "./Comments";
import Attachments from "./Attachments";
import History from "./History";

const tabItems = ["Comments", "Attachments", "History"];

const Activity = () => {
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  return (
    <div>
      <ButtonTabs items={tabItems} activeTab={selectedTab} onTabChange={setSelectedTab} />

      <div className="activity-content">
        {selectedTab === "Comments" && <Comments />}
        {selectedTab === "Attachments" && <Attachments />}
        {selectedTab === "History" && <History />}
      </div>
    </div>
  );
};

export default Activity;
