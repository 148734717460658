import React, { useState, useRef } from "react";
import { FaChevronDown } from "react-icons/fa";
import PropTypes from "prop-types";
import "./Accordion.scss"; // Include styles for the accordion

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <div className={`accordion-title ${isOpen ? "open" : ""}`} onClick={toggleAccordion}>
        {title}
        <span className={`accordion-icon ${isOpen ? "rotate" : ""}`}>
          <FaChevronDown />
        </span>
      </div>
      <div
        className={`accordion-content ${isOpen ? "open" : ""}`}
        style={{
          maxHeight: isOpen
            ? `${contentRef.current?.scrollHeight+20}px`
            : "0px",
        }}
        ref={contentRef}
      >
        <div className="accordion-content-inner">{content}</div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default Accordion;
