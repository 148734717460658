import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Tooltip.scss";

const Tooltip = ({ children, content, position }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = (state) => {
    setVisible(state);
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => toggleVisibility(true)}
      onMouseLeave={() => toggleVisibility(false)}
    >
      {children}
      {visible && (
        <div className={`tooltip-box ${position}`}>
          <div className="tooltip-arrow"></div>
          <div className="tooltip-content">{content}</div>
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired, // The element the tooltip wraps around
  content: PropTypes.node.isRequired, // The content to display inside the tooltip
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]), // Tooltip position
};

Tooltip.defaultProps = {
  position: "top", // Default position
};

export default Tooltip;
