import React from "react";
import PropTypes from "prop-types";
import "./RadioInput.scss"; // Include the styles for the radio buttons

const RadioInput = ({
  questionId,
  label,
  options = [], // Fallback for undefined options
  selectedAnswer,
  handleChange,
  isReadOnly,
}) => {
  return (
    <div className="radio-input-question">
      {/* Display question label */}
      <div className="radio-input-question_text">{label}</div>

      {/* Render radio button options */}
      <div className="radio-input-question_options multiple_choice">
        {options.map((option, index) => (
          <div key={index} className="form__radio-group">
            <input
              id={`${questionId}_${index}`}
              type="radio"
              className="form__radio-input"
              name={questionId}
              value={option.value}
              checked={selectedAnswer == option.value} // Use == for type-safe comparison
              onChange={(e) => handleChange(questionId, e.target.value)}
              disabled={isReadOnly} // Disable input in read-only mode
            />
            <label
              htmlFor={`${questionId}_${index}`}
              className="form__radio-label"
            >
              <span className="form__radio-button"></span>
              {option.text}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

RadioInput.propTypes = {
  questionId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedAnswer: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default RadioInput;