import React from "react";
import "./IncidentActionsSidebar.scss";

const IncidentActionsSidebar = ({ activeNav, onNavChange }) => {
  const navItems = [
    { id: "incidentForm", label: "Incident Form" },
    { id: "activity", label: "Activity" },
    { id: "actionPlanning", label: "Action Planning" },
  ];

  return (
    <div className="incident-actions-sidebar">
      <div className="incident-actions-sidebar-nav">
        {navItems.map((item) => (
          <div
            key={item.id}
            className={`incident-actions-sidebar-nav-item ${
              activeNav === item.id ? "active" : ""
            }`}
            onClick={() => onNavChange(item.id)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncidentActionsSidebar;
