import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import './DropdownInput.scss';

const DropdownInput = ({
    label,
    // options,
    selectedOption,
    onOptionSelect,
    customStyles = {},
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const handleMenuToggle = () => {
        setIsOpen((prev) => !prev);
    };

    const handleOptionSelect = (option) => {
        onOptionSelect(option);
        setIsOpen(false);
    };

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            className="dropdown-input-container"
            style={customStyles.container}
            ref={menuRef}
        >
            <label style={customStyles.label}>{label}:</label>
            <div className="dropdown-header" onClick={handleMenuToggle}>
                <span style={customStyles.selectedOption}>{selectedOption}</span>
                <FaChevronDown
                    className={`dropdown-icon ${isOpen ? 'rotate' : ''}`}
                />
            </div>
            <div
                className={`dropdown-input-list ${isOpen ? 'open' : ''}`}
                style={customStyles.list}
            >
                {/* {options &&
                    options.map((option, index) => (
                        <div
                            key={index}
                            className="dropdown-input-item"
                            style={customStyles.item}
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option.name}
                        </div>
                    ))} */}
            </div>
        </div>
    );
};

export default DropdownInput;
