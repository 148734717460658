import React from "react";
import PropTypes from "prop-types";
import "./TextInput.scss"; // Import the styles

const TextInput = ({
  label,
  name,
  value,
  onChange,
  type = "text",
  style = {},
  placeholder = "",
}) => {
  return (
    <div className="text-input">
      <label htmlFor={name}>{label}:</label>
      <input
        id={name}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        style={style}
        placeholder={placeholder}
      />
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default TextInput;
