import React, { useState, useEffect } from "react";
import IncidentActionsSidebar from "./IncidentActionsSidebar";
import AdminIncidentForm from "./AdminIncidentForm";
import IncidentActivity from "./IncidentActivity/IncidentActivity";
import IncidentActionPlanning from "./IncidentActionPlanning";
import "./IncidentActions.scss";
const IncidentActions = () => {
  const [activeNav, setActiveNav] = useState("incidentForm");
  const handleNavChange = (nav) => setActiveNav(nav);

  const renderContent = () => {
    if (activeNav === "incidentForm") {
      return <AdminIncidentForm />;
    }
    if (activeNav === "activity") {
      return <IncidentActivity />;
    }
    if (activeNav === "actionPlanning") {
      return <IncidentActionPlanning />;
    }
  };

  return (
    <div className="incident-actions">
      {/* Sidebar Navigation */}
      <IncidentActionsSidebar
        activeNav={activeNav}
        onNavChange={setActiveNav}
      />

      {/* Main Content */}
      <div className="incident-actions-content">
        <div className="incident-actions-content-header">
          <div className="incident-actions-content-header-title">
            {activeNav === "incidentForm"
              ? "Incident Form"
              : activeNav === "activity"
              ? "Activity"
              : "Action Planning"}
          </div>
        </div>

        {/* Dynamic Content */}
        <div className="incident-actions-content-body">{renderContent()}</div>
      </div>
    </div>
  );
};

export default IncidentActions;
