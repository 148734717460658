import React from "react";
import PropTypes from "prop-types";
import "./FormNav.scss";

const FormNav = ({ sections, selectedSection, onSectionSelect }) => {
  return (
    <div className="form-nav">
      {sections.map((section, index) => (
        <div
          key={section.id}
          className={`form-nav-item ${selectedSection?.id === section.id ? "active" : ""}`}
          onClick={() => onSectionSelect(section)}
        >
          <div className={`form-nav-item-number ${selectedSection?.id === section.id ? "active" : ""}`}>
            {index + 1}
          </div>
          <div className="form-nav-item-text">{section.name}</div>
        </div>
      ))}
    </div>
  );
};

FormNav.propTypes = {
  sections: PropTypes.array.isRequired,
  selectedSection: PropTypes.object,
  onSectionSelect: PropTypes.func.isRequired,
};

export default FormNav;