import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const getFormSections = async (header) => {
  try {
    const res = await instance.get(
      `api/incident-app/user/form-sections/`,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};


export const getFormSectionQuestions = async (header, sectionId) => {
  try {
    const res = await instance.get(
      `api/incident-app/user/form-sections/${sectionId}/`,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};
